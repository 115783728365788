<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos" v-html="`${monumentLanguage} >>`"></p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text"> {{ $t('default.SERVICES_BAR_EVENTS') }} </p>
                <!-- <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{info.nombres[0].texto}}</p> -->
            </div>
        </div>
        <div class="container-fluid px-5">
            <div class="d-flex justify-content-around pt-4 mb-3" style="">
                <div class="d-flex" style="width:100%">
                    <icon-base :width="55" :height="55" 
                        :iconColor="'rgb(0,0,0)'" >
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto spainhn-subtitle-2-black" v-html="monumentLanguage"></p>
                        <small class="mb-0 mt-auto spainhn-subtitle-4-black">{{address}}</small>
                    </div>
                </div>
            </div>
            
            <div class="d-flex mb-4">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards v-for="(entrada, index) in entradas" v-bind:key="index" @goToEntrada="goToEntrada($event)"
                        :tipo="'Evento'"
                        :proxDate="entrada.fechaInicioEvento"
                        :entradaId="entrada.visitaId"
                        :monumentoNombreURL="monumentoNombre"
                        :imageUrl="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.imagen}`"
                        :title="entrada.webName" 
                        :monument="entrada.monumentName" 
                        :date="entrada.fechas"
                        :precioOrientativo="entrada.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(entrada.precioOrientativo)}): ''"
                        />
                </div>
            </div>

        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import IconBase from '../components/IconBase.vue'
import IconCasttle from '../components/icons/IconCasttle.vue';
import Cards from '../components/Cards.vue'

export default {
    components:{
        DefaultLayout,
        IconBase,
        IconCasttle,
        Cards
    },
    data(){
        return{
            address: null,
            monumentoNombre: null,
            monumentoNombres: [],
            monumentoId: null,
            entradas: [],
            imagenesUrl: null,
        }
    },
    setup() {
        
    },
    methods:{
        goToEntrada(event){
            console.log(event)
            this.$router.push('/castillos/tarifas/'+this.monumentoNombre+'/'+event.entradaId + '?asfsdalk')
        },
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre)
        },
        getPrice( precioOrientativo ){
            if( precioOrientativo ){
                if( isNaN(Number(precioOrientativo)) ){
                    return '';
                }else{
                    return `${parseFloat(Number(precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}`
                }
            }else{
                return '';
            }
        },
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    computed:{
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        monumentLanguage(){
            let $vm = this;

            if( $vm.monumentoNombres[$vm.selectedIdiomaIndex] ){
                return $vm.monumentoNombres[$vm.selectedIdiomaIndex].texto;
            }
            else{
                return $vm.monumentoNombre;
            }
        }

    },
    created(){
        let $vm = this;
        $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        ApiService.getEdificacion($vm.$i18n.locale, $vm.monumentoNombre)
            .then( result => {
                // console.log(result.data[0]);
                let data = result.data;
                $vm.address = data.direccion;
                $vm.monumentoId = data.id;
                $vm.monumentoNombres = data.nombres;
                $vm.monumentLanguage = data.webName;
                // console.log(data);
                // $vm.edificios = [...result.data];
                    ApiService.getEventosByMonumento('es', $vm.monumentoId, "Evento")
                        .then( result => {
                            console.log(result.data);
                            $vm.entradas = [...result.data];

                            $vm.entradas.sort( function(a,b){                            
                                if(b.fechas.length > 0 && a.fechas.length > 0){
                                    return new Date(a.fechas[0]) - new Date(b.fechas[0])
                                }
                                else if(b.fechas.length > 0 && a.fechas.length == 0){
                                    return 1;
                                }
                                else if(b.fechas.length == 0 && a.fechas.length > 0){
                                    return -1;
                                }
                                else if(b.fechas.length == 0 && a.fechas.length == 0){
                                    if(a.fechaInicioEvento === undefined && b.fechaInicioEvento ){
                                        return 1;
                                    }
                                    else if(a.fechaInicioEvento && b.fechaInicioEvento){
                                        return  new Date( a.fechaInicioEvento ) - new Date( b.fechaInicioEvento );

                                    }
                                    else{
                                        return -1;
                                    }
                                }
                                else{
                                    return 0;
                                }
                            } )

                        })
                        .catch( err=> {
                            console.log(err);
                        })
            })
            .catch(err => {
                console.log(err);
            })
    }
}
</script>