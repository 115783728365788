var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c("p", {
            staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
            attrs: { role: "button" },
            domProps: { innerHTML: _vm._s(`${_vm.monumentLanguage} >>`) },
            on: { click: _vm.goToCastillos },
          }),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(" " + _vm._s(_vm.$t("default.SERVICES_BAR_EVENTS")) + " ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid px-5" }, [
      _c("div", { staticClass: "d-flex justify-content-around pt-4 mb-3" }, [
        _c(
          "div",
          { staticClass: "d-flex", staticStyle: { width: "100%" } },
          [
            _c(
              "icon-base",
              { attrs: { width: 55, height: 55, iconColor: "rgb(0,0,0)" } },
              [_c("icon-casttle")],
              1
            ),
            _c(
              "div",
              { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
              [
                _c("p", {
                  staticClass: "mb-0 mt-auto spainhn-subtitle-2-black",
                  domProps: { innerHTML: _vm._s(_vm.monumentLanguage) },
                }),
                _c(
                  "small",
                  { staticClass: "mb-0 mt-auto spainhn-subtitle-4-black" },
                  [_vm._v(_vm._s(_vm.address))]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "d-flex mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
          },
          _vm._l(_vm.entradas, function (entrada, index) {
            return _c("cards", {
              key: index,
              attrs: {
                tipo: "Evento",
                proxDate: entrada.fechaInicioEvento,
                entradaId: entrada.visitaId,
                monumentoNombreURL: _vm.monumentoNombre,
                imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.imagen}`,
                title: entrada.webName,
                monument: entrada.monumentName,
                date: entrada.fechas,
                precioOrientativo: entrada.precioOrientativo
                  ? _vm.$t("default.CARDS.START_FROM", {
                      msg: _vm.getPrice(entrada.precioOrientativo),
                    })
                  : "",
              },
              on: {
                goToEntrada: function ($event) {
                  return _vm.goToEntrada($event)
                },
              },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }